export type AdvisorRole = {
  id: number,
  title: string,
}

export const AdvisorRoles: AdvisorRole[] = [
  {
    id: 0,
    title: 'Lead generation'
  },
  {
    id: 1,
    title: 'Adding offers'
  },
  {
    id: 2,
    title: 'Making offers to investors'
  }
]